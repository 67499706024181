.box {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.category {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid #76446b;
  border-bottom: 0;
  padding: 0 10px;
  color: #76446b;
  width: 100%;
  height: 43px;

  &:hover {
    background-color: #d7cbd0;
  }
}

.box:last-of-type .category {
  border-bottom: 1px solid #76446b;
}

.exercise {
  width: 95%;
  height: 37px;
  display: flex;
  align-items: center;
  border: 1px solid #76446b;
  border-bottom: none;
  padding: 0 10px;
  color: #76446b;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  justify-content: space-between;
  background-color: #d7cbd0;
}

.exercise:last-of-type {
  border-bottom: 1px solid #76446b;
}

.visibilityIcon {
  margin: 2px 5px 0 10px;
  cursor: pointer;
}

.addIcon {
  margin: 2px 0 0;
  display: flex;
  cursor: pointer;
}

.expandIcon {
  margin: 2px 0 0;
}

.icons {
  display: flex;
  align-items: center;
}
