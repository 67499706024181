.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;

  @media (min-width: 1000px) {
    padding: 20px 40px;
  }
}

.form {
  display: flex;
  flex-direction: column;
}

.header {
  width: 100%;
  color: #76446b;
}

.mainInfo {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  @media (min-width: 1000px) {
    flex-wrap: nowrap;
  }
}

.smallText {
  font-size: 12px;
  color: #777;
}

.mainInfoLeft {
  flex-grow: 1;
  color: #76446b;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 20px 0;

  @media (min-width: 1000px) {
    padding: 0 150px 0 0;
  }
}

.mainFormData {
  flex-shrink: 0;
}

.moreInfo {
  margin: 20px 0 40px;
  width: 100%;
}

.days {
  width: 100%;
}

.day {
  color: #76446b;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  // margin: 40px 0 0;
  display: flex;
  align-items: center;

  @media (min-width: 1000px) {
    font-size: 36px;
  }
}

.addExercise {
  color: #76446b;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 40px 0 20px;
}

.exerciseBox {
  display: flex;
  width: 100%;

  @media (min-width: 1100px) {
    width: 50%;
  }
}

.selectedExercises {
  width: 100%;
}

.text {
  color: #164c8a;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin: 20px 0;
  padding: 0 20px;

  @media (min-width: 1000px) {
    font-size: 36px;
  }
}

.submit {
  text-align: center;
}

.buttons {
  margin: 40px 0 0;
}

.exerciseName {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  align-self: flex-end;
  padding: 0 0 15px;
}

.box {
  display: flex;
  flex-direction: column;
  padding: 22px 33px;
  justify-content: space-between;
  background: #d7e6d8;
  border-radius: 20px;

  @media (min-width: 1000px) {
    flex-direction: row;
  }
}

.imagePlaceholder {
  width: 195px;
  height: 235px;
  background: #ccc;
  flex-shrink: 0;
  margin: 30px 0 0;

  @media (min-width: 1000px) {
    margin: 0 0 0 100px;
  }
}

.editModeText {
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 0 42px;
  cursor: pointer;
}

.description {
  margin: 0 0 20px;
}

.centered {
  text-align: center;
}

.textCheck {
  color: #76446b;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 20px 0 0;
  width: 100%;
}

.headerNext {
  color: #76446b;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
  margin: 40px 0 10px;

  @media (min-width: 1000px) {
    font-size: 36px;
  }
}

.exerciseDayWrap {
  border: 1px solid #ccc;
  margin-bottom: 50px;
  padding: 20px;
  border-radius: 20px;
}

.confirmationMainData {
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}

.exerciseTop {
  display: flex;
  justify-content: space-between;
  margin: 50px 0 0;
}

.options {
  // border: 1px #6e759b solid;
  padding: 5px 10px;
  margin-bottom: -1px;
  position: relative;
  top: 20px;
  left: 10px;
}

.optionIcon {
  cursor: pointer;
}

.createNew {
  color: #164c8a;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 100px 0 40px;

  @media (min-width: 1000px) {
    font-size: 36px;
  }
}

.createNewSml {
  color: #164c8a;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 50px 0 40px;

  @media (min-width: 1000px) {
    font-size: 26px;
  }
}

.videoContainer {
  display: flex;
  justify-content: center;
}

.imgBox {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 280px;
  height: 280px;

  img {
    height: 100%;
  }
}

.playButton {
  position: absolute;
  opacity: 0.5;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.inputWithHelp {
  position: relative;
}

.helpIcon {
  position: absolute;
  left: 100%;
  top: 5px;
  z-index: 5555;
  transform: translate(5px, 0);
  color: #164c8a;
}
