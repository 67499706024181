.exercise {
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 0 0 80px;
  height: 315mm;
}

.exerciseName {
  font-weight: bold;
  font-size: 40px;
}

.settings {
  margin: 20px 0;
  font-size: 24px;
}

.description {
  margin: 0 0 40px;
  font-size: 26px;
}

.media {
  margin: 40px 0;
}
