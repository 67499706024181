.header {
  display: flex;
}

.title {
  position: relative;
  font-size: 28px;
  font-weight: 700;

  @media (min-width: 1000px) {
    font-size: 36px;
  }
}

@keyframes triangle-before {
  0%,
  80% {
    rotate: -45deg;
    scale: 1;
  }

  90% {
    scale: 0.5;
  }

  100% {
    rotate: 360deg - 45deg;
  }
}

@keyframes triangle-after {
  0%,
  80% {
    rotate: 45deg;
    scale: 1;
  }

  90% {
    scale: 1.5;
  }

  100% {
    rotate: 360deg + 45deg;
  }
}

.titleTriangle {
  position: absolute;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.titleTriangleBefore {
  width: 50px;
  height: 50px;
  right: 100%;
  top: -0.2em;
  rotate: -45deg;
  margin: 0.3em;
  animation: triangle-before 3s infinite ease-in-out;
}

.titleTriangleAfter {
  width: 12px;
  height: 12px;
  left: 100%;
  bottom: 0.4em;
  margin: 0.2em;
  rotate: 45deg;
  animation: triangle-after 3s 1.5s infinite ease-in-out;
}
