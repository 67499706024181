@import '../../styles/variables';

.mainBody {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background: $main-background;
  background: url(/assets/images/pics/layout/main_page_bg.png);
  background-position: center top;
  background-size: 100% auto;
}

.navPanel {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 90px;
  z-index: 1300;
  padding: 15px 0 15px 15px;
  background: #fff;
  position: fixed;

  @media (max-width: 700px) {
    padding: 5px;
    height: 60px;
  }
}

.mainPanel {
  flex: 1 1 auto;
  width: 100%;
  margin-top: 100px;

  @media (min-width: 700px) {
    padding: 0 70px 138px;
    margin-top: 110px;
  }

  html[data-embed='true'] & {
    margin-top: 0;
  }
}

.link {
  display: flex;
}

.logo {
  width: 250px;
  display: flex;
  margin: 0 40px 0 0;

  @media (max-width: 600px) {
    width: 200px;
  }

  img {
    width: 100%;
    height: auto;
    align-self: center;
  }
}

.mainUeBox {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 0 10px 10px;
  display: inline-block;
}

.mainUe {
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  margin: auto;
  margin-bottom: 30px;
  display: block;

  @media (max-width: 700px) {
    margin-bottom: 10px;
  }

  img {
    width: auto;
    height: 50px;
    vertical-align: middle;
    margin-right: 10px;

    @media (max-width: 700px) {
      height: 30px;
    }
  }
}

.navbar {
  display: flex;
  align-items: center;
  align-content: center;
  list-style-type: none;
  padding: 0;
  background: #0d4d8a;

  li {
    margin: 0 20px 0 0;
    padding: 10px;

    a {
      text-decoration: none;
      display: flex;
      text-transform: uppercase;
      width: 100%;
      color: #fff;
    }
  }
}

.active {
  background-color: $button-blue;
  border-radius: 10px;

  a {
    color: $main-white !important;
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: flex-end;

  a:not(.footerLogo) {
    align-self: flex-start;
    color: #164c94;
    text-decoration: none;
    margin: 0 0 10px;
    font-size: 14px;
  }
}

.footerLogo {
  width: 36.5%;
  max-width: 552px;
  margin: 0 0 30px 30px;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.footerSign {
  width: 56%;
  max-width: 849px;
  height: auto;
}
