.dialog {
  padding: 20px;
}

.title {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.video {
  display: flex;
  justify-content: center;
  margin: 30px 0 0;
}

.settings {
  margin: 10px 0;
}
