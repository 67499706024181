.container {
  width: 100%;
  max-width: 1340px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column wrap;
  padding: 20px;

  @media (min-width: 1000px) {
    padding: 20px 40px;
    flex-flow: column nowrap;
  }
}

.form {
  display: flex;
  flex-direction: column;
}

.person {
  margin: 20px auto 0;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  color: #164c8a;
}

.personData {
  flex-grow: 1;
  align-items: center;
  width: calc(100% - 80px);

  @media (min-width: 1000px) {
    width: auto;
  }
}

.photo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 0 20px 0 0;
  background: #ccc;

  @media (min-width: 1000px) {
    width: 100px;
    height: 100px;
  }
}

.personName {
  font-weight: bold;
  margin: 0 0 10px;
}

.booking {
  flex-shrink: 0;
  align-self: center;
  margin: 20px 0 0;

  @media (min-width: 1000px) {
    margin: 0 0 0 20px;
  }
}

.header {
  width: 100%;
  color: #164c8a;
  font-size: 28px;
  font-weight: 700;

  @media (min-width: 1000px) {
    font-size: 36px;
  }
}

.filters {
  display: flex;
  flex-wrap: wrap;
}

.formControl {
  width: 100%;

  @media (min-width: 1000px) {
    width: auto;
  }
}

.cities {
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  max-width: 200px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  padding: 6px;
}

.city {
  padding: 5px 6px;
  cursor: pointer;

  &:hover {
    background-color: #f3f3f3;
  }
}
