.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto 10px;
  align-items: center;
  padding: 20px;

  @media (min-width: 1000px) {
    padding: 20px 40px;
  }
}

.result {
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;

  @media (min-width: 1000px) {
    font-size: 36px;
  }
}

.advice {
  margin: 20px 0 0;
  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (min-width: 1000px) {
    font-size: 22px;
  }
}

.idText {
  margin: 0 0 20px;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (min-width: 1000px) {
    font-size: 22px;
  }
}

.idBox {
  height: 77px;
  border: 1px solid #ef4161;
  display: flex;
  align-items: center;
  padding: 0 40px;
  color: #ef4161;
  text-align: center;
  font-size: 18px;
  font-weight: 600;

  @media (min-width: 1000px) {
    font-size: 22px;
  }
}

.saveId {
  margin: 30px 0 0;
  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (min-width: 1000px) {
    font-size: 22px;
  }
}

.form {
  display: flex;
  flex-direction: column;

  @media (min-width: 1000px) {
    width: 400px;
  }
}

.goToPlanPreparation {
  margin: 40px 0 0;
}
