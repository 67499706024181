.container {
  margin: 100px auto;
}

.text {
  color: #76446b;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin: 40px 0;
  padding: 0 20px;

  @media (min-width: 1000px) {
    font-size: 36px;
  }
}

.submit {
  text-align: center;
}
