.container {
  width: 100%;
  height: 520px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  width: 800px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 50px;

  @media (min-width: 1000px) {
    transform: translateY(30%);
  }
}

.boxPatient {
  background: #fff;

  .littleHeader,
  .header,
  .description {
    color: #e65f5c;
  }
}

.boxPhysio {
  background: #fff;

  .littleHeader,
  .header,
  .description {
    color: #0d4d8a;
  }
}

.littleHeader {
  font-size: 18px;
  text-transform: uppercase;
  margin: 30px 0 0;
  text-align: center;
}

.header {
  margin: 10px 0;
  font-size: 28px;
  font-weight: bold;
  color: #fff;
  text-align: center;

  @media (min-width: 1000px) {
    font-size: 36px;
  }
}

.description {
  margin: 0 0 20px;
  text-align: center;
  color: #d7cbd0;
}
