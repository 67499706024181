.container {
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 10px;
  align-items: center;
  flex-direction: column;
}

.mainBox {
  padding: 40px;
  flex-direction: row;
}

.mainBoxLeft {
  width: 50%;
  display: flex;
  justify-content: right;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0 100px 0 0;
}

.mainBoxRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
}

.day {
  font-weight: bold;
  text-transform: uppercase;
}

.pagination {
  display: flex;
  justify-content: center;
}

.exercise {
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 0 0 80px;
}

.header {
  color: #ef4161;
  width: 100%;
}

.topHeader {
  color: #000;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 80px 0 40px;

  @media (min-width: 1000px) {
    font-size: 36px;
  }
}

.desc {
  color: #000;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
}

.physioData {
  margin: 40px 0;
  text-align: center;
  font-size: 19px;
  line-height: 26px;
}

.physioDataActive {
  text-align: right;
  font-size: 19px;
  line-height: 26px;
  margin: 0 0 10px;
}

.label {
  color: #a7a8a7;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.value {
  color: #414142;
}

.textForPatientHeader {
  color: #000;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 40px 0 0;
}

.textForPatient {
  color: #000;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 20px 0 60px;
}

.footerStats {
  width: 100%;
  color: #000;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.buttonContainer {
  margin: 40px 0;
}

.dayHeader {
  color: #000;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 20px;

  @media (min-width: 1000px) {
    font-size: 36px;
  }
}

.exercises {
  margin: 60px 0 20px;
}

.media {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}

.exerciseName {
  color: #000;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0 20px;

  @media (min-width: 1000px) {
    font-size: 36px;
    padding: 0;
  }
}

.settings {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 30px 0;
  padding: 0 20px;

  @media (min-width: 1000px) {
    padding: 0;
  }
}

.description {
  color: #000;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 20px;

  @media (min-width: 1000px) {
    padding: 0;
  }
}

.isFinished {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

.isFinishedQuestion {
  color: #000;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 30px 0 20px;
  text-transform: uppercase;
  text-align: center;
}

.isFinishedDesc {
  color: #000;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin: 0 0 45px;
}

.allReadyOne {
  color: #00b815;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (min-width: 1000px) {
    font-size: 36px;
  }
}

.allReadyTwo {
  color: #00b815;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 32px 0 0;
}

.ratings {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
}

ul {
  justify-content: center !important;
}

button.Mui-selected {
  position: relative;
  z-index: 6;
}

.TherapyPlan_progressSpan {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background: green;
  content: ' ';
  z-index: 0;
}

.box {
  width: 100%;
}

.therapyInfoTop {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.therapyInfoId {
  color: #414142;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 13px 0 0;
}

.therapyInfoHeader {
  color: #ef4161;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.monitoringBox {
  margin: 40px 0 0;
}

.vote {
  border-radius: 50%;
  border: 1px #000 solid;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  cursor: pointer;

  &:hover {
    background-color: #a7a8a7;
  }
}

.videoImg {
  width: 360px;
  height: 480px;
}

.videoContainer {
  display: flex;
  justify-content: center;
}

.imgBox {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.playButton {
  position: absolute;
  opacity: 0.5;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.downloadPdf {
  cursor: pointer;
}

.pdfFile {
  padding: 40px;
}
