.pros {
  background: #fff;
  width: 100%;
  display: flex;
  justify-content: space-around;
  border-radius: 12px;
  max-width: 1340px;
  margin: 0 auto;
  flex-wrap: wrap;

  @media (min-width: 1000px) {
    flex-wrap: nowrap;
  }
}

.item {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px #76446b solid;
  justify-content: space-around;

  @media (min-width: 1000px) {
    width: 24%;
  }
}

.iconBox {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 24px 12px;
}

.icon {
  max-width: 64px;
  max-height: 64px;
}

.data {
  display: flex;
  flex-direction: column;
}

.header {
  width: 100%;
  color: #76446b;
  padding: 0 20px;
  text-align: center;
  margin: 0 0 10px;
}

.description {
  font-size: 12px;
  margin: 10px 0 0;
  background: #76446b;
  color: #fff;
  text-align: center;
  padding: 20px;

  @media (min-width: 1000px) {
    padding: 20px 40px;
  }
}
