.container {
  width: 100%;

  // background: #fff;
  border-radius: 12px;
  max-width: 1340px;
  margin: 0 auto;
}

.containerMargin {
  margin: 10px 0;
}

.header {
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 20px;
}

.errorMessage {
  margin: 20px 0;
  color: red;
  padding: 0 20px;
  text-align: center;
}

// form

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.formContainer {
  display: flex;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.formButtons {
  align-self: flex-end;
  display: flex;
  gap: 20px;
  margin: 50px 0 0;
}

.formButtonsFirst {
  align-self: flex-start;
  margin: 0 0 50px;
}

.formGoBack {
  display: flex;
  color: #164c8a;
  font-size: 19px;
  font-weight: 400;
  text-transform: none;

  &:before {
    content: '';
    width: 25px;
    height: 15.625px;
    margin: 10px 10px 10px 0;
    background: url(../assets/icons/GoBack.svg) no-repeat center / contain;
  }
}

// adding specificy
.formRow.formRow {
  display: flex;
  flex-flow: column;
  width: 100%;
  margin: 0 0 20px;
}

.formError.formError {
  margin: -15px 0 20px;
  padding: 0 15px;
  font-size: 10px;
  color: #d32f2f;

  &:empty {
    display: none;
  }
}
