.container {
  max-width: 1340px;
  margin: 0 auto;
  width: 100%;
}

.summary {
  color: rgb(0, 128, 251);
  text-transform: uppercase;
  font-weight: bold;
}

.header {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 24px;
  margin: 20px 0;
}

.linkType {
  margin: 60px 0 20px;
  color: #000;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (min-width: 1000px) {
    font-size: 36px;
  }
}

.options {
  display: flex;
  margin: 10px 0;
}

.option {
  display: flex;
  align-items: center;
  align-content: center;
  margin: 0 20px 0 0;
  cursor: pointer;
}

.icon {
  margin: 0 5px 0 0;
}

.successDesc {
  color: #76446b;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.qrCode {
  margin: 20px 0 30px;
}
