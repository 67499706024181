.container {
  margin: 20px auto;
  padding: 0 20px;
  position: relative;

  @media (min-width: 1000px) {
    padding: 0;
  }
}

.group {
  margin: 40px 0;
}

.categoryName {
  font-weight: bold;
  width: 100%;
  font-size: 24px;
}

.exercises {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 20px 0 0;
  justify-content: space-between;
}

.exercise {
  font-size: 16px;
  width: 100%;
  border: 1px #ccc solid;
  padding: 20px;
  margin: 0 0 20px;

  @media (min-width: 1000px) {
    width: 24%;
  }
}

.exerciseId {
  margin: 0 0 10px;
}

.exerciseEmpty {
  width: 24%;
}

.exerciseDesc {
  margin: 10px 0 0;
  white-space: break-spaces;
}

.videoImg {
  width: 240px;
  height: 280px;
}

.videoContainer {
  display: flex;
  justify-content: center;
}

.imgBox {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.playButton {
  position: absolute;
  opacity: 0.5;
  margin: 0 auto;
  left: 0;
  right: 0;
}
