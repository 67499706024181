.alert {
  background: rgba(0, 0, 0, 0.93);
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  text-align: center;
  padding: 50px 50px 0;
  color: #fff;
  font-size: 1rem;
}
