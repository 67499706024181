.carousel {
  background: #e5f2ff;
  padding: 10px 60px;
}

.navLink,
.placeholder {
  width: 100%;

  @media (min-width: 1000px) {
    width: 31%;
  }
}

.navLink {
  background: #e5f2ff;
  margin: 20px 0;
  height: 517px;
  display: flex;
  text-decoration: none;
}

.buttonBox {
  padding: 20px;
}

.infodemia {
  padding: 40px;
  margin: 50px auto 0;
  color: #76446b;

  @media (max-width: 766px) {
    padding: 10px;
  }
}

.infodemiaHeader {
  margin: 20px auto 0;
  display: inline-block;
  padding: 20px;
  color: #0d4d8a;
  border: 1px solid #0d4d8a;
  text-transform: uppercase;

  @media (max-width: 766px) {
    padding: 10px;
    font-size: 1.5rem;
  }
}

.infodemiaText {
  color: #0d4d8a;
  padding: 40px;
  border: 1px solid #0d4d8a;
  position: relative;
  top: -1px;
  width: 80%;
  font-size: 1.4rem;
  margin: auto;

  @media (max-width: 766px) {
    font-size: 1rem;
    width: 100%;
    padding: 10px;
  }
}

.patientHeader {
  display: inline-block;
  margin-left: 0;
  text-transform: uppercase;
  margin-bottom: 0;
  padding: 20px;
  color: #fff;
  background: #e65f5c;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);

  @media (max-width: 500px) {
    padding: 10px;
    font-size: 1.5rem;
    text-align: center;
    display: block;
  }
}

.fizjoHeader {
  display: inline-block;
  margin-left: 0;
  text-transform: uppercase;
  margin-bottom: 0;
  padding: 20px;
  color: #fff;
  background: #0d4d8a;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);

  @media (max-width: 500px) {
    padding: 10px;
    font-size: 1.5rem;
    display: block;
    text-align: center;
  }
}

.patientWrapper {
  background: #e65f5c;
  color: #fff;
}

.fizjoWrapper {
  background: #0d4d8a;
  color: #fff;
}

.fizjoWrapperBoxes {
  flex-direction: row-reverse;
}

.patientWrapperBoxes,
.fizjoWrapperBoxes {
  display: flex;

  h2 {
    margin-top: 10px;
    margin-bottom: 0;
  }

  span {
    font-size: 0.8rem;
  }

  @media (max-width: 766px) {
    flex-direction: column;
  }
}

.patientWrapperBoxLeft {
  display: flex;
  justify-content: center;
  border-right: 1px solid rgba(255, 255, 255, 0.6);
  align-items: center;
  text-align: center;
  font-size: 1.2rem;
  width: 50%;
  color: #fff;
  text-decoration: none;

  .patientWrapperBox {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    padding: 40px;
    width: 100%;
    height: 100%;
    color: #fff;
    text-decoration: none;
    transition: all 0.3s ease;
  }

  @media (max-width: 766px) {
    width: 100%;
    border-bottom: 1px solid #fff;

    .patientWrapperBox {
      padding: 20px;
    }
  }
}

.patientWrapperBoxRight {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;

  a.patientWrapperBox {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    padding: 40px;
    width: 100%;
    color: #fff;
    text-decoration: none;
    transition: all 0.3s ease;
  }

  .patientWrapperBox:nth-child(1) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  }

  @media (max-width: 766px) {
    width: 100%;
  }

  .patientWrapperBox {
    padding: 20px;
  }
}

a.patientWrapperBox:hover,
a.fizjoWrapperBox:hover {
  background: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
}

a.fizjoWrapperBox {
  color: #fff;
  text-decoration: none;
}

.fizjoWrapperBoxLeft {
  display: flex;
  justify-content: center;
  border-left: 1px solid rgba(255, 255, 255, 0.6);
  align-items: center;
  text-align: center;
  font-size: 1.2rem;
  width: 50%;

  .fizjoWrapperBox {
    width: 100%;
    transition: all 0.3s ease;
    height: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    padding: 40px;
  }

  @media (max-width: 766px) {
    width: 100%;
    border-bottom: 1px solid #fff;
  }

  .fizjoWrapperBox {
    padding: 20px;
  }
}

.fizjoWrapperBoxRight {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;

  .fizjoWrapperBox {
    display: flex;
    transition: all 0.3s ease;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    padding: 40px;
    width: 100%;
  }

  .fizjoWrapperBox:nth-child(1) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  }

  @media (max-width: 766px) {
    width: 100%;

    .fizjoWrapperBox {
      width: 100%;
    }
  }
}

.arts {
  display: flex;
  justify-content: space-around;
  margin: 100px auto 0;
  max-width: 1340px;
  width: 100%;
  flex-wrap: wrap;

  @media (min-width: 1000px) {
    flex-wrap: wrap;
  }
}

.art {
  width: 100%;
  background: #e5f2ff;
  height: 517px;
  display: flex;
  position: relative;
}

.artFirst {
  width: 100%;
  height: 517px;
  margin: 20px 0;
  display: flex;
  background: #efada9;
  align-items: center;
  flex-direction: column;
  position: relative;
  justify-content: center;

  svg {
    position: absolute;
    left: 100%;
    top: 50%;
    z-index: 999;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  @media (min-width: 1000px) {
    width: 31%;
  }
}

.patientArts {
  .artFirst {
    background: #d7cbd0;
    color: #ef4161;
  }

  .artBoxBg {
    background: #d7cbd0;
  }

  .artBox:hover {
    background: #d7cbd0;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  }

  .artBox,
  .artCategorySub,
  .artCategoryHeader {
    color: #ef4161;
  }
}

.artCategorySub {
  color: #76446b;
}

.artCategoryHeader {
  font-weight: bold;
  color: #76446b;
  font-size: 30px;
  margin: 20px;
}

.artCategory {
  margin: 0 0 10px;
  text-transform: uppercase;
  z-index: 5;
  position: relative;
  font-size: 12px;
}

.artImg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.artBoxBg {
  width: 100%;
  height: 100%;
  align-self: flex-end;
  background: rgba(239, 173, 169, 1);
  mix-blend-mode: color;
  padding: 20px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #76446b;
  z-index: 1;
  transition: all 0.3s ease;
}

.artBox {
  width: 100%;
  z-index: 5;
  height: 100%;
  align-self: flex-end;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: all 0.3s ease;
  color: #76446b;

  &:after {
    inset: 0;
    position: absolute;
    z-index: 0;
    content: ' ';
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 8%,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &:hover {
    background: rgba(239, 173, 169, 1);
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  }
}

.artTitle {
  font-weight: bold;
  font-size: 18px;
  position: relative;
  z-index: 5;
  margin: 0 0 10px;
}

.artCategoryHeaderIcon {
  @media (max-width: 1000px) {
    display: none;
  }
}
