.unique {
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (min-width: 1000px) {
    font-size: 36px;
  }
}

.linkBox,
.filledBy,
.date {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.linkBox {
  margin: 1rem 0;
}

.table {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 50px auto;

  @media (min-width: 1000px) {
    width: 890px;
  }
}

.row {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (min-width: 1000px) {
    flex-wrap: nowrap;
  }
}

.name {
  width: 100%;
  border-left: 1px solid #c3c4d2;
  border-top: 1px solid #c3c4d2;
  background: #d9d9d9;
  display: flex;
  align-items: center;
  padding: 10px 0 10px 10px;

  @media (min-width: 1000px) {
    width: 227px;
  }
}

.data {
  border-top: 1px solid #c3c4d2;
  border-right: 1px solid #c3c4d2;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;

  @media (min-width: 1000px) {
    width: 513px;
  }
}

.norms {
  display: none;

  @media (min-width: 1000px) {
    border-top: 1px solid #c3c4d2;
    border-right: 1px solid #c3c4d2;
    width: 116px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 10px 0;
  }
}

.link {
  cursor: pointer;
  color: blue;
}

.last {
  width: 856px;
  display: flex;
  border-top: 1px solid #c3c4d2;
}

.dataGood {
  color: #00b815;
}

.dataBad {
  color: #ef4161;
}

.dataAverage {
  color: #f0a202;
}

.diagnosisPain {
  display: block;

  &__man {
    display: inline-block;
    width: 50%;
    position: relative;

    img {
      width: 100%;
      pointer-events: none;
      position: relative;
      z-index: 2;
    }

    &_bg {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      top: 0;
      z-index: 1;

      &_head {
        width: 10%;
        height: 9%;
        left: 50%;
        top: 8%;
        transform: translateX(-50%);
      }

      &_headBack {
        width: 10%;
        height: 10%;
        left: 50%;
        top: 12%;
        transform: translateX(-50%);
      }

      &_neck {
        width: 21%;
        height: 6%;
        left: 50%;
        top: 17%;
        transform: translateX(-50%);
      }

      &_chest {
        width: 11%;
        height: 7%;
        left: 50%;
        top: 24%;
        transform: translateX(-50%);
      }

      &_thoracic {
        width: 9%;
        height: 10%;
        left: 50%;
        top: 23%;
        transform: translateX(-50%);
      }

      &_lumbar {
        width: 13%;
        height: 13%;
        left: 50%;
        top: 33%;
        transform: translateX(-50%);
      }

      &_pelvis {
        width: 13%;
        height: 5%;
        left: 50%;
        top: 42%;
        transform: translateX(-50%);
      }

      &_belly {
        width: 12%;
        height: 7%;
        left: 50%;
        top: 33%;
        transform: translateX(-50%);
      }

      &_leftArm {
        width: 5%;
        height: 13%;
        left: 39%;
        top: 23%;
      }

      &_rightArm {
        width: 5%;
        height: 13%;
        left: 56%;
        top: 23%;
      }

      &_leftHand {
        width: 5%;
        height: 13%;
        left: 38%;
        top: 40%;
      }

      &_rightHand {
        width: 5%;
        height: 13%;
        left: 57%;
        top: 40%;
      }

      &_leftPeace {
        width: 7%;
        height: 13%;
        left: 43%;
        top: 51%;
      }

      &_rightPeace {
        width: 6%;
        height: 13%;
        left: 51%;
        top: 51%;
      }

      &_leftFoot {
        width: 7%;
        height: 21%;
        left: 43%;
        top: 69%;
      }

      &_rightFoot {
        width: 7%;
        height: 21%;
        left: 51%;
        top: 69%;
      }

      &_leftTibia {
        width: 7%;
        height: 13%;
        left: 43%;
        top: 51%;
      }

      &_rightTibia {
        width: 6%;
        height: 13%;
        left: 51%;
        top: 51%;
      }

      &_leftThigh {
        width: 7%;
        height: 21%;
        left: 43%;
        top: 69%;
      }

      &_rightThigh {
        width: 7%;
        height: 21%;
        left: 51%;
        top: 69%;
      }

      div {
        position: absolute;
        background: #f7f7f7;

        &.DiagnosisQuestions_diagnosis_man_active {
          cursor: pointer;
          background: #c00;
        }
      }
    }
  }
}
