.container {
  margin: 0 0 10px;
  padding: 40px 20px;
}

.header {
  font-weight: bold;
  font-size: 20px;
  margin: 0 0 20px;
}

.exercise {
  margin: 20px 0 0;
}

.doneBox {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.isDone {
  text-transform: uppercase;
  color: rgb(0, 128, 251);
  font-weight: bold;
  font-size: 24px;
  margin: 10px 0;
}

.doneText {
  margin: 0 0 20px;
}
