.navLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 20px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.9rem;
  height: 100%;
}

.navLink:hover {
  background: rgba(0, 0, 0, 0.2);
}

.icon {
  width: 20px;
  margin: 0 10px 0 0;
}

.label {
  color: #fff;
}

.box {
  flex-grow: 1;
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.navItems {
  display: none;

  @media (min-width: 1000px) {
    display: flex;
    justify-content: flex-end;
  }
}

.navMobileItems {
  background: #000;

  @media (min-width: 1000px) {
    display: none;
  }
}
