.container {
  --color: #e3676f;
}

.linkBoxes {
  display: flex;
  width: 100%;
  margin: 10px auto;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (min-width: 1000px) {
    flex-wrap: nowrap;
    padding: 30px 0;
  }
}

.linkBox {
  flex-grow: 1;
  display: flex;
  padding: 10px 0;
  flex-wrap: wrap;

  @media (min-width: 1000px) {
    margin: 0 24px;
    flex-wrap: nowrap;
  }
}

.boxes {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (min-width: 1000px) {
    flex-wrap: nowrap;
  }
}
