.container {
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto 20px;
  align-items: center;

  @media (min-width: 1000px) {
    padding: 20px 40px;
  }
}

.formControlLabel {
  margin: 20px 0 0;

  @media (min-width: 1000px) {
    margin: 0 0 0 22px;
  }
}

.formControlLabelWithDesc {
  margin: 20px 0 0;

  @media (min-width: 1000px) {
    margin: 10 22px;
  }
}

.header {
  color: #ef4161;
  font-size: 36px;
  font-weight: 700;
}

.textH {
  color: #000;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  margin: 60px 0;
}

.sectionTop {
  display: flex;
  width: 100%;
  align-items: center;
}

.sectionHeader {
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 0 10px;
}

.sectionIcon {
  align-self: baseline;
  margin: 3px 0 0;
}

.sectionTitle {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.sectionDesc {
  color: #9499b4;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.optionTitle {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (min-width: 1000px) {
    font-size: 19px;
  }
}

.optionDesc {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  @media (min-width: 1000px) {
    font-size: 14px;
  }
}

.section {
  margin: 40px 0;
}

.diagnosisPain {
  display: block;

  &__man {
    display: inline-block;
    width: 50%;
    position: relative;

    img {
      width: 100%;
      pointer-events: none;
      position: relative;
      z-index: 2;
    }

    &_bg {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      top: 0;
      z-index: 1;

      &_head {
        width: 10%;
        height: 9%;
        left: 50%;
        top: 8%;
        transform: translateX(-50%);
      }

      &_headBack {
        width: 10%;
        height: 10%;
        left: 50%;
        top: 12%;
        transform: translateX(-50%);
      }

      &_neck {
        width: 21%;
        height: 6%;
        left: 50%;
        top: 17%;
        transform: translateX(-50%);
      }

      &_chest {
        width: 11%;
        height: 7%;
        left: 50%;
        top: 24%;
        transform: translateX(-50%);
      }

      &_thoracic {
        width: 9%;
        height: 10%;
        left: 50%;
        top: 23%;
        transform: translateX(-50%);
      }

      &_lumbar {
        width: 13%;
        height: 13%;
        left: 50%;
        top: 33%;
        transform: translateX(-50%);
      }

      &_pelvis {
        width: 13%;
        height: 5%;
        left: 50%;
        top: 42%;
        transform: translateX(-50%);
      }

      &_belly {
        width: 12%;
        height: 7%;
        left: 50%;
        top: 33%;
        transform: translateX(-50%);
      }

      &_leftArm {
        width: 5%;
        height: 13%;
        left: 39%;
        top: 23%;
      }

      &_rightArm {
        width: 5%;
        height: 13%;
        left: 56%;
        top: 23%;
      }

      &_leftHand {
        width: 5%;
        height: 13%;
        left: 38%;
        top: 40%;
      }

      &_rightHand {
        width: 5%;
        height: 13%;
        left: 57%;
        top: 40%;
      }

      &_leftPeace {
        width: 7%;
        height: 13%;
        left: 43%;
        top: 51%;
      }

      &_rightPeace {
        width: 6%;
        height: 13%;
        left: 51%;
        top: 51%;
      }

      &_leftFoot {
        width: 7%;
        height: 21%;
        left: 43%;
        top: 69%;
      }

      &_rightFoot {
        width: 7%;
        height: 21%;
        left: 51%;
        top: 69%;
      }

      &_leftTibia {
        width: 7%;
        height: 13%;
        left: 43%;
        top: 51%;
      }

      &_rightTibia {
        width: 6%;
        height: 13%;
        left: 51%;
        top: 51%;
      }

      &_leftThigh {
        width: 7%;
        height: 21%;
        left: 43%;
        top: 69%;
      }

      &_rightThigh {
        width: 7%;
        height: 21%;
        left: 51%;
        top: 69%;
      }

      div {
        position: absolute;
        background: #f7f7f7;

        &:hover,
        &.DiagnosisQuestions_diagnosis_man_active {
          cursor: pointer;
          background: #c00;
        }
      }
    }
  }

  @media (max-width: 900px) {
    &__man {
      display: block;
      width: 100%;
    }
  }
}

.painModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background: #fff;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  padding: 3rem;
  line-height: normal;

  @media (max-width: 600px) {
    width: 90%;
    line-height: 0.5 !important;
  }

  @media (max-height: 700px) {
    transform: translate(-50%, 0);
    top: 10px;
  }
}

.group {
  display: none;

  @media (min-width: 1000px) {
    display: inline-flex;
    margin: 0 10px 10px 0;
  }
}

.groupFlex {
  display: flex;
  flex-direction: row;
}

.singleResult {
  font-size: 22px;
  font-weight: 600;
  display: flex;
  align-items: end;
  justify-content: center;
  margin: 0 0 40px;
  position: relative;
}

.singleResultLine {
  width: 1px;
  height: 30px;
  display: flex;
  border-left: 1px solid #ccc;
}

.groupResult {
  font-size: 22px;
  font-weight: 600;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: end;
  justify-content: center;
  margin: 0 0 40px;
  position: relative;
}

.groupResultType {
  color: #ccc;
  background-color: #fff;
  padding: 5px;
  transform: translateY(18px);
}

.groupResultBox {
  position: absolute;
  bottom: -40px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 5px;
  height: 19px;
  background-color: #9499b4;
  padding: 0 5px;
  white-space: nowrap;
}

.good {
  background-color: #00b815;
}

.bad {
  background-color: #ef4161;
}

.average {
  background-color: #f0a202;
}

// .questions {
//   width: 600px;
// }

// .question {
//   font-weight: bold;
//   margin: 20px 0;
// }

// .answers {
//   margin: 0 0 20px;
// }

// .answer {
//   display: flex;
//   align-items: center;
//   margin: 0 0 10px;
//   cursor: pointer;
// }

// .number {
//   border-radius: 50%;
//   background: #fff;
//   border: 1px #1976d2 solid;
//   width: 20px;
//   height: 20px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   line-height: 20px;
//   font-size: 12px;
//   margin: 0 10px 0 0;
// }

// .answerSelected {
//   .number {
//     background: #000;
//     color: #fff;
//   }
// }

.desktopSeparator {
  display: none;

  @media (min-width: 1000px) {
    display: block;
    width: 100%;
    height: 20px;

    &asBr {
      height: 0;
    }
  }
}

.form {
  display: block;
  flex-direction: column;

  @media (min-width: 1000px) {
    display: block;
  }
}

.pinModal form {
  display: block;
}
