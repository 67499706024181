.pros {
  // background: #fff;
  width: 100%;
  display: flex;
  justify-content: space-around;
  border-radius: 12px;
  max-width: 1340px;
  margin: 40px 0 0;
  flex-wrap: wrap;

  @media (min-width: 1000px) {
    flex-wrap: nowrap;
    margin: 80px auto;
  }
}

.item {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 0 20px;

  @media (min-width: 1000px) {
    width: 33.3%;
    margin: 0;
  }
}

.iconBox {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px 0 0;
  position: relative;

  @media (min-width: 1000px) {
    padding: 0 30px 0 20px;
  }
}

.icon {
  max-width: 30px;
  max-height: 30px;
  width: auto;
  position: absolute;
  left: 23px;

  @media (min-width: 1000px) {
    left: 43px;
  }
}

.data {
  display: flex;
  flex-direction: column;
}

.header {
  width: 100%;
  margin: 0 0 10px;
  font-size: 16px;
  font-weight: 600;

  @media (min-width: 1000px) {
    font-size: 18px;
  }
}

.description {
  font-size: 14px;
  font-weight: 400;

  @media (min-width: 1000px) {
    font-size: 16px;
  }
}
