.pros {
  // background: #fff;
  width: 100%;
  display: flex;
  justify-content: space-around;
  border-radius: 0;
  max-width: 1340px;
  flex-wrap: wrap;
  border: 1px solid #0d4d8a;

  @media (min-width: 1000px) {
    flex-wrap: nowrap;
    margin: 0 auto 80px;
  }
}

.item {
  width: 100%;
  display: flex;
  align-items: center;

  @media (min-width: 1000px) {
    width: 33.3%;
    gap: 10px;
  }
}

.item:nth-child(2) {
  border: 1px solid #0d4d8a;
  border-width: 0 1px;

  @media (max-width: 766px) {
    border-width: 1px 0;
  }
}

.iconBox {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  background: #0d4d8a;
  padding: 0 50px;

  @media (max-width: 766px) {
    border-bottom: 1px solid #fff;
  }
}

.icon {
  max-width: 30px;
  max-height: 30px;
  width: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.data {
  display: flex;
  padding: 20px;
  flex-direction: column;
  color: #0d4d8a;
}

.header {
  width: 100%;
  margin: 0 0 10px;
  font-size: 16px;
  font-weight: 600;

  @media (min-width: 1000px) {
    font-size: 18px;
  }
}

.description {
  font-size: 14px;
  font-weight: 400;

  @media (min-width: 1000px) {
    font-size: 16px;
  }
}
