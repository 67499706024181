.uuid {
  color: #000;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0 20px;

  @media (min-width: 1000px) {
    font-size: 36px;
    padding: 0;
  }
}

.topText,
.checkDiagnosis {
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 11px 0;
  padding: 0 20px;

  @media (min-width: 1000px) {
    padding: 0;
  }
}

.checkDiagnosis {
  cursor: pointer;
}

.exercisesStatus {
  margin: 40px 0;
}

.days {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
}

.day {
  display: flex;
  flex-wrap: wrap;
  background: #f5f6fd;
  padding: 20px;
  justify-content: space-between;
  margin: 20px 0;
  border-radius: 20px;
  position: relative;
}

.dayInProgress {
  background: #e2effe;
}

.dayDone {
  background: #ebffed;
}

.progressIcon {
  position: absolute;
  top: 20px;
  left: 20px;
}

.dayData {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 20px 0;

  @media (min-width: 1000px) {
    width: 24%;
    padding: 0;
  }
}

.dayExercise,
.dayMonitoring {
  width: 100%;
  padding: 40px 40px 60px;
  background: #fff;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  min-height: 130px;
  position: relative;

  @media (min-width: 1000px) {
    width: 24%;
  }
}

.dayMonitoring {
  padding: 40px 40px 0;
}

.dayExerciseEmpty {
  width: 24%;
}

.exerciseDone {
  color: #00b815;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  right: 20px;
  top: 10px;
}

.exeDesc {
  position: absolute;
  bottom: 20px;
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
}

.monitoring {
  font-weight: 700;
}

.monitoringQuestion {
  margin: 10px 0 0;
  text-align: center;
}

.monitoringAnswer {
  margin: 20px 0;
  font-weight: bold;
}

.dayExerciseBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.exeNumber {
  margin: 0 0 10px;
  font-weight: 700;
  text-align: center;
  width: 100%;
}
