.container {
  padding: 0 20px;
}

.header {
  margin: 0 70px;
  color: #164c8a;

  [data-app-type='patient'] & {
    color: #ef4161;
  }

  [data-app-type='physiotherapist'] & {
    color: #76446b;
  }
}

.content {
  margin: 70px 20px;
  font-size: 19px;
  font-weight: 400;
  color: #414142;

  @media (min-width: 600px) {
    margin: 70px;
  }

  p {
    margin: 2em 0;
  }
}
